<template>
  <div class="addArticle">
    <div class="title">
      <span>课时管理 / </span>
      <span class="black">课时{{ edit ? "编辑" : "新增" }} </span>
    </div>
    <el-card>
      <el-form :model="addForm" label-width="130px" :rules="rules" ref="addForm">
        <el-row :gutter="24">
          <el-col :xs="24" :md="14">
            <el-form-item label="课时名称：" prop="itemName">
              <el-input v-model="addForm.itemName" placeholder="请输入课时名称"></el-input>
            </el-form-item>
            <el-form-item label="作者：" prop="author">
              <el-input v-model="addForm.author" placeholder="请输入作者"></el-input>
            </el-form-item>
            <el-form-item label="简要描述：" prop="itemTitle">
              <el-input type="textarea" placeholder="选填"
                v-model="addForm.itemTitle" maxlength="120" :rows="4" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="课时视频：" :required="true">
               <div class="flex">
                <el-upload :action="action2" accept=".mp4" :headers="headers"
                  :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                  <el-button size="small" type="primary" :loading="loading">{{!loading?"添加课时":"上传中"}}</el-button>
                </el-upload>
                <span class="upload_span_text">建议上传尺寸16:9，仅支持MP4格式</span>
              </div>
              <div>
                <video-player v-if="addForm.contentUrl" class="video-player vjs-custom-skin" ref="videoPlayer"
                  :playsinline="true" :options="playerOptions">
                </video-player>
              </div>
            </el-form-item>
            <el-form-item label="封面图片：" prop="coverUrl">
              <div class="flex">
                <el-upload :action="action1" accept=".png,.jpg,.jpeg,.bmp,.webp" :headers="headers"
                  :show-file-list="false" :on-success="ImgSuccess">
                  <el-button size="small" type="primary">选择资源文件</el-button>
                </el-upload>
                <span class="upload_span_text">建议上传尺寸121pt*92pt，仅支持png/jpg/jpeg/bmp/webp格式</span>
              </div>
              <div>
                <img v-if="addForm.coverUrl" :src="addForm.coverUrl" class="img_240" />
              </div>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :md="10">
            
          </el-col>
        </el-row>
        <el-form-item label="课时介绍：" prop="itemIntroduce">
          <TinyMceEditor v-model="addForm.itemIntroduce" />
        </el-form-item>
        <el-form-item class="text_center">
          <!-- state 0上架  2待上架 -->
          <el-button type="primary" size="small" @click="submit('1')">上线</el-button>
          <el-button type="primary" size="small" plain @click="submit('0')">保存待投放</el-button>
          <el-button size="small" @click="cancel()">取消</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
  import TinyMceEditor from "@/components/TinyMceEditor";
  import modal from "@/components/modal";
  import {
    topicTypes,
    files1,
    files2,
    courseTimeSave,
    courseTimeUpdate,
    courseTimeInfo,
  } from "@/api/api";
  export default {
    components: {
      TinyMceEditor,
      modal
    },
    data() {
      return {
        active: "",
        resetDialog: false,
        minioStyle: "",
        fileInfo: {
          id: "",
          fileTitle: "",
          type:2

        },
        addForm: {
          itemName: "",
          author: "",
          itemIntroduce: "",
          itemTitle:"",
          coverUrl: "",
          status: "",
          contentUrl:"",
          duration:"",
        },
        rules: {
          adaptorType: [{
            required: true,
            message: "请选择所属板块"
          }],
          courseType: [{
            required: true,
            message: "请选择所属分类"
          }],
          itemName: [{
            required: true,
            message: "请输入课时名称",
          }],
          author: [{
            required: true,
            message: "请输入来源"
          }],
          coverUrl: [{
            required: true,
            message: "请上传封面图片"
          }],

        },
        typeList: [],
        vedioList: [],
        action1: files1.url,
        action2: files2.url,
        loading: false, //上传状态
        externalLoading: false, //七牛和阿里上传loading
        percentage: 0, //上传进度
        timer: null, //上传进度定时器
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: this.$util.getToken("access_token"),
        },
        playerOptions: {
          playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
          autoplay: false, // 如果为true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 是否视频一结束就重新开始。
          preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: "zh-CN",
          aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [{
            type: "video/mp4", // 类型
            src: "", // url地址
          }, ],
          poster: "", // 封面地址
          notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true, // 当前时间和持续时间的分隔符
            durationDisplay: true, // 显示持续时间
            remainingTimeDisplay: false, // 是否显示剩余时间功能
            fullscreenToggle: true, // 是否显示全屏按钮
          },
        },
        edit: "",
      };
    },
    computed: {
      previousForm() {
        return this.$store.state.previousForm;
      },
      uploadStyle() {
        return this.$store.state.uploadStyle;
      },
    },
    created() {
      if (this.$route.params.id != "true") {
        this.edit = true;
        this.getCourseInfo(this.$route.params.id);
      }
      if (this.$route.params.form) {
        this.$store.commit("setParam", {
          previousForm: this.$route.params.form
        });
      }
    },
    methods: {
      // 查看详情
      getCourseInfo(val) {
        this.$request(courseTimeInfo,{id:val}).then((res) => {
          if (res.code === 0) {
            this.addForm = res.data;
            this.playerOptions.sources = [{
                type: "video/mp4", // 类型
                src: res.data.contentUrl, // url地址
              }, ];
          }
        });
      },
      // 上传视频
      handleAvatarSuccess(res, file) {
        if (res.code == 0) {
          this.percentage = 100
          clearInterval(this.timer)
          this.$nextTick(() => {
            this.loading = false
          })
          this.$util.message("suc", "上传成功");
          this.playerOptions.sources = [{
            type: "video/mp4", // 类型
            src: res.data.url, // url地址
          }, ];
          this.addForm.contentUrl = res.data.url;
          this.addForm.duration = res.data.duration;
          this.$refs.addForm.validate((result) => {
            return true
          })
        } else {
          this.$util.message("err", res.message);
          this.loading = false

        }
      },
       beforeAvatarUpload(file) {
        this.percentage = 0
        this.addForm.contentUrl = ""
        const isLt2M = file.size / 1024 / 1024 < 500;
        if (!isLt2M) {
          this.$util.message("warning", "上传头像图片大小不能超过 500MB!");
        }
        this.loading = true
        this.timer = setInterval(() => {
          if (this.percentage < 95) {
            this.percentage++
          } else {
            clearInterval(this.timer)
          }
        }, 1000);
        return isLt2M;
      },
      // 上传封面图
      ImgSuccess(res, file) {
        if (res.code == 0) {
          this.$util.message("suc", "上传成功");
          this.addForm.coverUrl = res.data.url;
          this.$refs.addForm.validate((result) => {
            return true
          })
        } else {
          this.$util.message("err", res.message);
        }
      },
      submit(val) {
        let url = this.edit ? courseTimeUpdate : courseTimeSave;
        this.addForm.status = val
        this.$refs.addForm.validate((result) => {
          if (result) {
            this.$request(url, this.addForm).then((res) => {
              if (res.code === 0) {
                this.$util.message("suc", "操作成功");
                if (this.edit) {
                  this.getCourseInfo(this.$route.params.id);
                }
                this.$router.push({
                  name: "coursesTime",
                  params: {
                    form: this.previousForm
                  }
                });
              }
            });
          }
        });
      },
      cancel() {
        this.$router.push({
          name: "coursesTime",
          params: {
            form: this.previousForm
          }
        });
      },
    },
  };
</script>

<style lang="less" scoped>
  .addArticle {
    .img_240 {
      width: 50%;
      min-width: 150px;
      max-width: 240px;
      height: 180px;
      border: 1px solid #eeeeee;
    }

    /deep/.w_100 {
      width: 480px !important;
      margin-right: 20px;
    }

    .topic{
      width:calc(100% - 40px);
      height: 200px;
      overflow-y: scroll;
      border: 1px solid #eee;
      padding: 20px;
      .zsgc {
      color: #409EFF;
      text-decoration: underline;

      .span {
        margin-left: 10px;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    }

    .selectBox {
      height: 300px;
      line-height: 30px;
      text-align: center;
      overflow-y: scroll;
      border: 1px solid #eee;
      padding-bottom: 20px;
      margin: 0 50px;

      .dis {
        width: 100%;
        height: 40px;
      }

      .active {
        width: 99%;
        background: #eee;
        border: 1px solid #eee;
        // color: #fff;
      }
    }

    .w_104 {
      width: 104px;
    }

    /deep/.el-textarea__inner {
      padding: 5px 40px 5px 15px;
    }
  }
</style>